import { UserInfo } from '@core/models/interfaces/auth';
import { createAction, props } from '@ngrx/store';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';

export const register = createAction('[Auth Page] Register', props<{ email: string; password: string }>());
export const registerSuccess = createAction('[Auth Page] Register');
export const registerFailed = createAction('[Auth Page] Register', props<{ error: string }>());

export const login = createAction('[Auth Page] Login', props<{ email: string; password: string }>());
export const loginSuccess = createAction(
  '[Auth Page] Login Success',
  props<{ accessToken: string; userInfo?: UserInfo }>()
);
export const loginFailed = createAction('[Auth Page] Login Failed', props<{ error: string }>());

export const setAuthState = createAction(
  '[Auth Page] Set Auth State',
  props<{ accessToken: string; userInfo?: UserInfo; isLoggedIn: boolean }>()
);

export const logout = createAction('[Auth Page] Logout');

export const updateUserInfoField = createAction(
  '[Auth Page] Update UserInfo Field',
  props<{ field: keyof UserInfo; value: string | boolean }>()
);

export const otpRequired = createAction('[Auth] OTP Required', props<{ email: string }>());
export const verifyOtp = createAction('[Auth Page] Verify OTP', props<{ email: string; otp: string }>());
export const verifyOtpFailed = createAction('[Auth Page] Verify OTP Failed', props<{ error: string }>());
export const verifyOtpSuccess = createAction(
  '[Auth Page] Verify OTP Success',
  props<{ accessToken: string; userInfo?: UserInfo }>()
);

export const setFacebookAccounts = createAction(
  '[Facebook Page] Set Facebook Accounts State',
  props<{ facebookAccounts: FacebookAccount[] }>()
);

export const setFacebookAccountSelected = createAction(
  '[Facebook Page] Set Facebook Account Selected State',
  props<{ facebookAccount: FacebookAccount }>()
);

export const setBusinessIdSelected = createAction(
  '[Business Page] Set Business Id Selected State',
  props<{
    id: string;
  }>()
);

export const refreshBusinessPortfolio = createAction('[Business Page] Refresh Business Portfolio');
